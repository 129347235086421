
import {Component, Vue, Prop} from 'nuxt-property-decorator'
import Product from '@/std/Product';
import svgCart from '~/components/svg/cart.vue'

@Component({components: {svgCart}})
export default class extends Vue {
  @Prop() product!: Product
  @Prop({default: 'w-56'}) width!: string

  inCart() {
    if (this.$nuxt.$isServer) return false
    return !!this.$store.state.cart.products.find((v: { code: string }) => `${v.code}` === `${this.product.code}`)
  }

  addToCart() {
    this.$root.$emit('cart', {...this.product, count: 1})
    this.$nextTick(this.$forceUpdate)
    this.$buefy.toast.open({
      duration: 5000,
      message: this.$t('component.cart.add', {product: this.product.name}).toString(),
      position: 'is-bottom',
    })
  }

}
