
import { Component, Vue, Prop } from 'nuxt-property-decorator'
@Component
export default class extends Vue {
  @Prop({ default: '-' }) name: any
  @Prop({ default: false }) isH1!: boolean

  getName() {
    return this.name
    // return this.$isServer ? "[[?H1?]]" : this.name;
  }

}
