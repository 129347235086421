
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import FullFixed from './FullFixed.vue'
import svgPercent from '~/components/svg/percent.vue'
@Component({
  components: { FullFixed, svgPercent }
})
export default class extends Vue {
  @Prop({ default: '' }) title!: String
  @Prop({ default: '' }) subTitle!: String

  isMore = false

}
